import * as R from 'ramda';
import React, { useState } from 'react';

import {
  compose,
  withHandlers,
  withState,
  withProps
} from 'recompose';

import {
  TextInput,
  Button
} from '../../../../uikit/UIKit';

import withForm from '@thecodeisgreen/withform';
import { libs, formatters, validations } from '@fasstech/spid-front';
import useKeyPressEnter from '../../../../hooks/useKeyPressEnter';
import TurnoversByOfferType from './turnover/TurnoversByOfferType';
import checkContractNumberIsOld from './checkContractNumberIsOld';
import disableContractNumberInput from '../../../../lib/disableContractNumberInput';

const enhance = compose(
  withForm,
  withState(
    'contractNumbers',
    'setContractNumbers',
    ({ value, contractNumbers }) => {
      let v = R.repeat('', value);
      if (R.isNil(contractNumbers) || R.isEmpty(contractNumbers)) return v;
      return v.map((v, index) => {
        return !R.isNil(contractNumbers[index]) ? contractNumbers[index] : '';
      });
    }
  ),
  withHandlers({
    onChange: ({ contractNumbers, setContractNumbers, onEdit, offerType }) => (i, v) => {
      setContractNumbers(R.update(i, formatters.formatContractNumber(i, v, offerType), contractNumbers));
      onEdit(true);
    },
    onSave: ({ onSave, contractNumbers, offerType }) => () => {
      onSave(contractNumbers, offerType);
    },
    getAllContractNumbers: ({ contractNumbers, relatedContract, ccn }) => () => {
      let numbers = contractNumbers;
      const contractNumbersCheckIsDisabled = R.pathOr(false, ['relatedContractsSettings', 'contractNumbersCheckDisabled'], ccn);
      if (relatedContract.checked && !contractNumbersCheckIsDisabled) {
        numbers = R.concat(R.propOr([], 'contractNumbers')(relatedContract))(numbers);
      }
      return numbers;
    }

  }),
  withProps(
    ({ getAllContractNumbers, contractNumbers, offerType, turnoversAreValid }) => {
      return ({
        disabled: newContractNumbers => {
          const contractNumbersAreValid = R.reduce(
            (r, v) => R.compose(
              R.and(r),
              validations.contractNumberIsValid,
            )(v, offerType)
              && validations.contractNumbersAreUniques(getAllContractNumbers()),
            true,
            contractNumbers
          );

          return !contractNumbersAreValid || !turnoversAreValid(newContractNumbers);
        }
      });
    }
  )
);

const ContractNumbers = enhance(
  ({
    ccnId,
    ccn,
    contractDescription,
    contractNumbers,
    contractNumbersAreDefined,
    disabled,
    edited,
    onChange,
    onSave,
    getAllContractNumbers,
    amendment,
    contractNumbersAutoGeneration,
    offerType,
    staff,
    hideTurnovers
  }) => {
    useKeyPressEnter(onSave, !disabled && edited && !contractNumbersAreDefined);

    const [defaultContractNumbers] = useState(contractNumbers);

    const _checkContractNumberIsOld = checkContractNumberIsOld({
      contractNumbers,
      contractDescription,
      amendment,
      ccn,
      offerType,
      defaultContractNumbers
    });

    return <div className="flex flex-col gap-y-12">
      {contractNumbers.map(
        (v, i) => {
          const label = libs.getContractNumberLabel(i, R.length(contractNumbers), contractDescription, ccnId, ccn, offerType);
          const isOldContractNumber = _checkContractNumberIsOld(i);

          const isDisabled = disableContractNumberInput({
            defaultContractNumbers,
            amendment,
            contractNumbersAutoGeneration,
            index: i
          });

          return <div key={`contractNumbers-${i}`} className="mb-2">
            <div className="field field-text-input !mb-0">
              <span>{label}</span>
              <TextInput
                type="text"
                value={v}
                onChange={e => onChange(i, e.target.value)}
                placeholder="1234567P"
                style={{ maxWidth: '140px', borderColor: !validations.contractNumberIsValid(v, offerType) && !isOldContractNumber ? 'orange' : undefined }}
                disabled={isDisabled}
              />
            </div>

            <TurnoversByOfferType
              offerType={offerType}
              contractNumberIndex={i}
              staff={staff}
              ccn={ccn}
              hideTurnover={hideTurnovers || isOldContractNumber}
            />
          </div>;
        }
      )}

      {!hideTurnovers && offerType === 'SANTE' && <div className="text-blue-400 italic text-[14px] mb-6">(valeur TTC du tarif ou % PMSS mensuel de la formule x effectif global x 12)</div>}

      {!validations.contractNumbersAreUniques(getAllContractNumbers()) &&
        <div style={{ color: 'red', 'font-size': '0.8em' }}>Les numéros de contrats ne doivent pas être identiques.</div>
      }

      {(edited || !contractNumbersAreDefined) && <Button
        style={{
          marginBottom: '50px',
          width: '200px'
        }}
        onClick={onSave}
        disabled={disabled(contractNumbers)}
      >
        Enregistrer
      </Button>}
    </div>;
  });

export default ContractNumbers;
